/* Főoldali felugró ablak */
.reg-box {
	display: none;
	position: absolute;
	z-index: 301;
	/* top: 48px; */
	/* left: -5px; */
	width: 400px;
	background-color: #e2e6e9;
	padding: 16px 16px 20px;
	-moz-box-shadow: 2px 5px 3px 3px rgba(68,68,68,0.3);
	-webkit-box-shadow: 2px 5px 3px 3px rgba(68,68,68,0.3);
	box-shadow: 2px 5px 3px 3px rgba(68,68,68,0.3);
	box-sizing: border-box;
}

@media (max-width: 766px) {
	.reg-box {
		-moz-box-shadow: 2px 5px 3px 3px rgba(68,68,68,0.3);
		-webkit-box-shadow: 2px 5px 3px 3px rgba(68,68,68,0.3);
		box-shadow: 2px 5px 3px 3px rgba(68,68,68,0.3);
	}
}
@media (max-width: 550px) {
	.reg-box {
		width: auto;
	}
}
@media (max-width: 500px) {
	.sign-in .reg-box,
	.profile .reg-box {
		transform: translateX(-30%);
	}
}

.reg-box .inner-flex {

}

@media (max-width: 640px) {
	.reg-box .inner-flex {
		flex-direction:column-reverse;
	}
}

.reg-box .data-fields {
	margin-right: 16px;
	width: 50%;
}

@media (max-width: 640px) {
	.reg-box .data-fields {
		margin-right: 0;
		width: 100%; /* mod */
	}
}

/*  */
.register-required {
	background-color: #e2e6e9;
	padding: 24px;
	-moz-box-shadow: 2px 5px 3px 3px rgba(68,68,68,0.3);
	-webkit-box-shadow: 2px 5px 3px 3px rgba(68,68,68,0.3);
	box-shadow: 2px 5px 3px 3px rgba(68,68,68,0.3);
	max-width: 450px;
	box-sizing: border-box;
}

.register-required a {
	text-decoration:underline;
}

.register-required a:hover, .register-required a.btn {
	text-decoration:none;
}

.register-required .event-box.smaller {
	background:none #e2e6e9;
	padding: 0 0 24px;
	min-height: auto;
	box-sizing:border-box;
	width:100%;
}

.register-required .reg-box {
	display: block;
	position: static;
	width: 100%;
	box-sizing:border-box;
	padding:0px 0px 32px;
	box-shadow: 0 0 transparent;
}

.register-required .reglink {
	padding-top: 16px;
	font-size: 16px;
	border-top: 1px solid rgb(180,180,180);
}

.register-required .reg-box .or:after {
	display:none;
}

.register-required .reg-box .or {
	color:#7b7a9a
}

.register-required .fancybox-close-small::after {
	background: none;
}

.event-box.smaller.messages-success {
	height: auto;
}

.event-box.smaller.messages-success-pending {
	height: auto;
}

.event-box.smaller.messages-error {
	height: auto;
}


.reg-box-date {
	font-size: 16px;
}

.reg-box .login-failed-message {
	font-size: 14px;
	color: red;
	display: inline-block;
	padding: 9px 0px;
}

.event-box{
	display: inline-block;
	position: relative;
	top: 0;
	left: 0;
	width:100%;
	max-width:600px;
	/*width: 600px;*/
	min-height: 300px;
	overflow:hidden;
	padding: 16px;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px !important;
}

.fancybox-skin {
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px !important;
}

.media-newsletter-dialog,
.forgotpassword-form {
	font-size: 18px;
	max-width: 480px;
	height: auto;
	text-align: center;
	padding:32px 16px 32px;
	box-sizing: border-box;
}

.media-newsletter-dialog {
	max-width: 370px;
}

.media-newsletter-dialog .messages-success,
.forgotpassword-form .messages-success,
.forgotpassword-form .messages-unknown-error {
	font-size: 16px;
}

.media-newsletter-dialog .media-details {
	margin-bottom: 20px;
	font-size: 18px;
}
.forgotpassword-form .forgotpass-details {
	text-align: left;
	margin-bottom: 20px;
}

.media-newsletter-dialog input,
.forgotpassword-form input {
	width: 100%;
	box-sizing: border-box;
	padding: 3px 8px 0;
	line-height: 28px;
	height: 38px;
}

.media-newsletter-dialog .btn,
.forgotpassword-form .btn {
	margin: 20px 0 10px;
}

.forgotpassword-container {
	width: 420px;
	margin-left: 32px;
	text-align: center;
}
.forgotpassword-container label {
	width: 150px;
	padding: 8px 0;
	text-align: left;
}
.forgotpassword-container input {
	width: 250px;
	height: 38px;
}
.forgotpassword-container .btn {
	margin: 16px 0;
}

.event-box.smaller {
	box-sizing:border-box;
	width:420px;
	min-height:150px;
	text-align: center;
	margin: 0 auto;
	background: #fff url(../images/bet-logo-simple.svg) no-repeat bottom 20px right 50%;
	background-size:64px;
	padding: 32px 16px 84px;
}

/* --- */
.event-box.smaller[data-fragment="registration-success-pending-dialog"] {
	width:400px;
}

.event-box.smaller[data-fragment="unregistration-success-dialog"] {
	width:450px;
}

@media (max-width:500px) {
	.event-box.smaller,
	.event-box.smaller[data-fragment="unregistration-success-dialog"] {
		width:100%;
	}
}

span.first-line-title {
	font-size:24px;
}

.header-top .header-nav .login-li.mobile-hover {
	background-color: #e2e6e9;
	-moz-box-shadow: 2px 2px 3px 3px rgba(68,68,68,0.3);
	-webkit-box-shadow: 2px 2px 3px 3px rgba(68,68,68,0.3);
	box-shadow: 2px 2px 3px 3px rgba(68,68,68,0.3);
}

@media (max-width: 500px) {
	.header-top .header-nav .sign-in:hover {
		margin-left: 0 !important;
	}
}
.header-top .header-nav .sign-in .reg-box {
	z-index: 500;
	top: 34px;
}

.header-top .header-nav .login-li > a {
	cursor: pointer;
}

.header-top .header-nav .login-li.mobile-hover .reg-box {
	display: block;
}

.header-top .header-nav .profile {
	/* padding: 8px; */
	margin: 0;
	background-color: #f3f3f6;
}
.header-top .header-nav .profile:hover a {
	/* cursor: default; */
	text-decoration: none;
}
.header-top .header-nav .profile .reg-box {
	padding: 6px 0;
	width: auto;
	left: 0;
}
.header-top .header-nav .profile .reg-box .item {
	text-transform: none;
	color: #7b7a9a;
	font-size: 16px;
	font-weight: normal;
	margin: 0 32px;
	cursor: pointer;
	display: inline-block;
}

.header-top .header-nav .profile .reg-box span.item > span {
	display: inline-block;
	margin: 6px 0;
}

.header-top .header-nav .profile .reg-box .item:hover {
	text-decoration:underline;
}

.header-top .profile .reg-box .item.profile-mail {
	cursor: default;
	padding: 0;
	display: inline-block;
}
.header-top .header-nav .profile-mail::before {
	content: "";
	display: inline-block;
	width: 12px;
	height: 15px;
	margin: 0 8px -1px -20px;
	background-image: url(../images/profile-icon.svg);
	background-size: 100%;
	background-repeat: no-repeat;
}

.header-top .header-nav .profile span {
	color: #9295b8;    /*#b2b5c8;*/
}

.header-top .header-nav .fav-link {
	padding: 6px;
	margin: -6px -6px -6px 11px;
	background-color: #f3f3f6;
}
.header-top .header-nav .fav-link:hover {
	background-color: #e2e5e8;
	-moz-box-shadow: 2px 1px 3px 3px rgba(68,68,68,0.3);
	-webkit-box-shadow: 2px 1px 3px 3px rgba(68,68,68,0.3);
	box-shadow: 2px 1px 3px 3px rgba(68,68,68,0.3);
}

.media-newsletter-dialog .title,
.reg-box .title,
.event-box .title {
	font-size: 16px;
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
}
@media (max-width: 980px) {
	.media-newsletter-dialog .title,
	.reg-box .title,
	.event-box .title {
		font-size: 14px;
	}
}
@media (max-width: 766px) {
	.media-newsletter-dialog .title,
	.reg-box .title,
	.event-box .title {
		font-size: 14px;
	}
}

.media-newsletter-dialog .title-error,
.reg-box .title-error,
.event-box .title-error,
.auth-container .title-error,
.event-box .other-error {
	display: inline-block;
	color: red;
	font-size: 14px;
	margin: 2px 0 0;
	text-align: right;
}
.reg-box .title-error {
	margin: 0;
}
.event-box .other-error {
	width: 150px;
	text-align: left;
	font-size: 13px;
	margin: 0 0 0 10px;
}
.event-box .other-error .error {
	margin: 0;
}

.media-newsletter-dialog .title-error,
.event-box .title-error {
	font-size: 13px;
}

.reg-box .input {
	width: 100%;
	border: 1px solid rgb(180,180,180);
	height: 38px;
	line-height: 28px;
	font-size: 16px;
	padding: 3px 8px 0 !important;
	box-sizing: border-box;
}
.reg-box .input[type=password] {
	display: inline-block;
	width: calc(100% - 50px);
	border-right: 0;
}
.reg-box div .search-norm {
	position: absolute;
	width: 50px;
	line-height: 38px;
	height: 38px;
}

.reg-box .right {
	float: right;
	/*text-decoration: underline;*/
	font-size: 16px;
	margin-bottom: 6px;
	cursor: pointer;
}

.reg-box.event-box .right {
	margin-left: 0;
}

.reg-box .or {
	margin-bottom: 13px;
	font-size: 16px;
	clear:both;
}

.reg-box .or::after {
	display: inline-block;
	content: "";
	background: url(../images/bgim.png) repeat-x;
	width: 257px;
	height: 1px;
}

.auth-container .selectContainer.selectContainer2{
	margin-left: 0;
	margin-bottom: 20px;
}

.passwordStrength .strength-meter {
	border-radius: 2px;
	border: 1px solid #aaa;
	overflow: hidden;
	margin: 5px 0;
}

.passwordStrength .strength-meter::after {
	content: "";
	display: block;
	width: 0;
	background-color: red;
	height: 5px;
	padding: 1px;
	box-sizing: border-box;
	background-clip: content-box;
	transition: 0.3s ease width, 0.3s ease background-color;
}
.passwordStrength .strength-meter[data-value="0"]::after {
	width: 20%;
	background-color: #cc4533;
}
.passwordStrength .strength-meter[data-value="1"]::after {
	width: 40%;
	background-color: #ff8f39;
}
.passwordStrength .strength-meter[data-value="2"]::after {
	width: 60%;
	background-color: #ffd700;
}
.passwordStrength .strength-meter[data-value="3"]::after {
	width: 80%;
	background-color: #50924d;
}
.passwordStrength .strength-meter[data-value="4"]::after {
	width: 100%;
	background-color: #1E611B;
}

.media-newsletter-dialog .selectContainer.selectContainer2,
.event-box .selectContainer.selectContainer2,
.reg-box .selectContainer.selectContainer2 {
	margin-left: 0;
	margin-bottom: 10px;
	width: 100%;
}


/* Buttons */

.auth-container .btn.btn,
.reg-box .btn.btn {
	vertical-align: middle !important;
	padding: 5px 17px 0 !important;
}

.bet-articles .right.flexy-element .btn.btn {
	margin-top: 4px;
	padding: 0px 20px;
}

.btn.btn.fb {
	display: block;
	position: relative;
	background-color: #3b5998;
	color: white;
	text-align: left;
	margin: 28px 0 20px;
	padding-left: 48px !important;
	text-transform: none;
	line-height: 38px;
}
.reg-box .btn.btn.fb {
	margin: 20px 0;
}
.btn.btn.fb:hover {
	background-color: #2c4372;
	text-decoration: none;
}
.btn.btn.fb::before {
	display: block;
	content: "";
	position: absolute;
	width: 11px;
	height: 20px;
	top: 11px;
	left: 16px;
	background-image: url(../images/f.png);
	background-repeat: no-repeat;
	background-size: 100%;
}

.btn.btn.google {
	display: block;
	position: relative;
	background-color: #db3236;
	color: white;
	text-align: left;
	margin: 10px 0 0;
	padding-left: 48px !important;
	text-transform: none;
	line-height: 38px;
}
.btn.btn.google:hover {
	background-color: #bb1216;
	text-decoration: none;
}
.btn.btn.google::before {
	display: block;
	content: "";
	position: absolute;
	width: 13px;
	height: 20px;
	top: 10px;
	left: 16px;
	background-image: url(../images/g.png);
	background-repeat: no-repeat;
	background-size: 100%;
}

/* Profil oldal */

h1.sectionhead {
	margin-left: 0 !important;
	font-weight: 400 !important;
}

.auth-container h2 {
	font-weight: 400 !important;
}

.auth-container .selectContainer {
	margin-bottom: 20px;
	margin-left: 130px;
}
.auth-container .selectContainer3 {
	margin-right: 205px;
	margin-top: 20px;
}
@media (max-width: 880px) {
	.auth-container .selectContainer3 {
		margin-right: 21.6%;
	}
}
@media (max-width: 680px) {
	.auth-container .selectContainer {
		margin-left: 0;
	}
	.auth-container .selectContainer3 {
		margin-right: 21.6%;
	}

	.auth-container .selectContainer select {
		width:100%;
	}

	.auth-container .selectContainer .select2-container {
		width:100%!important;
	}

}
@media (max-width: 560px) {
	.auth-container .selectContainer3 {
		margin-right: 0;
	}
}

.auth-container .small {
	margin-left: 165px;
	width: 490px;
}
@media (max-width: 680px) {
	.auth-container .small {
		margin-left: 0;
		width: auto;
	}
}
.auth-container .small.flexy input {
	width: 100%;
}

.auth-container .small .selectContainer {
	margin-left: -35px;
}
@media (max-width: 680px) {
	.auth-container .small .selectContainer {
		margin-left: 0;
	}
}
.auth-container .small.accordion-panel {
	border-bottom: 1px solid #e2e5e8;
	border-top: 1px solid #e2e5e8;
}
.auth-container .small .accordion-header {
	font-size: 20px !important;
	border: 0 !important;
}
.auth-container .small .accordion-group {
	border: 0 !important;
}
.auth-container .closer {
	padding-left: 35px;
	padding-top: 0;
}

.auth-container .title {
	font-size: 18px;
	text-align: left;
	margin-top: 0;
	margin-bottom: 5px;
	-ms-flex-item-align: end;
	align-self: flex-end;
}

@media (max-width: 980px) {
	.auth-container .title {
		font-size: 18px;
	}
}
@media (max-width: 766px) {
	.auth-container .title {
		font-size: 16px;
	}
}

.auth-container .input {
	width: 490px;
	border: 1px solid rgb(180,180,180);
	height: 38px;
	line-height: 28px;
	font-size: 19px;
	padding: 3px 8px 0 !important;
	box-sizing: border-box;
}
@media (max-width: 560px) {
	.auth-container .input {
		width: 100%;
	}
}

::-webkit-input-placeholder {
	color: #b0b0c0 !important;
}
:-moz-placeholder { /* Firefox 18- */
	color: #b0b0c0 !important;
}
::-moz-placeholder {  /* Firefox 19+ */
	color: #b0b0c0 !important;
}
:-ms-input-placeholder {
	color: #b0b0c0 !important;
}
.auth-container input[type="radio"] {
	display: none;
	margin: 0 8px 1px 0;
}
.auth-container label > input[type="radio"] + span {
	display: inline-block;
	background-image: url(../images/radio.svg);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	height: 16px;
	width: 16px;
	margin: 6px 10px -3px 0;
}
.auth-container label > input[type="radio"]:checked + span {
	background-image: url(../images/radiochecked.svg);
}

.auth-container input[type='checkbox'],
.event-box input[type='checkbox'] {
	display: none;
	float: left;
	clear: none;
	margin: 0;
}

.auth-container label > input[type='checkbox'] + span,
.event-box label > input[type='checkbox'] + span {
	display: inline-block;
	background-image: url(../images/checkbox.jpg);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	height: 16px;
	width: 16px;
	margin: 5px 10px -2px 0;
}
.event-box label > input[type='checkbox'] + span {
	margin-bottom: -3px;
}
.auth-container label > input[type='checkbox']:checked + span,
.event-box label > input[type='checkbox']:checked + span {
	background-image: url(../images/checked.jpg);
}




.auth-container .button-container {
	width: 100%;
	text-align: center;
	margin: 20px 0;
}
.auth-container .message-success {
	margin: 20px 0;
	color: #50924d;
}

.auth-container .social-container {
	border-left: 1px solid #b4b9bd;
	width: 310px;
	height: 230px;
	margin-left: 40px;
	padding-left: 43px;
}
@media (max-width: 1100px) {
	.auth-container .social-container {
		margin-left: 20px;
		padding-left: 23px;
	}
}

.auth-container .address {
	width: 100%;
	margin: 30px 0 10px 130px;
}
.auth-container .address::after {
	display: inline-block;
	content: "";
	background: url(../images/bgim.png) repeat-x;
	width: 425px;
	height: 1px;
}
@media (max-width: 680px) {
	.auth-container .address {
		margin-left: 0;
	}
}
span.address.list-attribute {
	margin-right: 8px;
}

.auth-container .agree {
	color: #7b7a9a !important;
	text-align: center;
}

.event-box .agree {
	color: #7b7a9a !important;
	font-size: 16px;
}
.event-box .agree label {
	font-weight: normal !important;
}

.event-box .agree a {
	color: #7b7a9a !important;
	text-decoration:underline;
}

.event-box .agree a:hover {
	text-decoration:none;
}

label {
	font-weight: 400;
}


/* Floaties, flexies */

.floaty {
	width: 490px;
}
.floaty.reg {
	width: 100%;
	margin-top: 32px;
}
@media (max-width: 560px) {
	.floaty {
		width: 100%;
	}
}

.floaty::after {
	display: block;
	content: "";
	clear: both;
}

.registration-title {
	position: relative;
}

.registration-title .registration-success {
	display: block;
	transition: 0.7s cubic-bezier(0,1,0.5,1) transform, 0.7s ease opacity, 0.7s ease visibility;
}

.registration-title .registration-inprogress {
	display: block;
	position: absolute;
	transition: 0.3s ease transform, 0.3s ease opacity, 0.3s ease visibility;
}

.registration-title[data-reg-status="inprogress"] .registration-success {
	transform: translateX(-100px);
	opacity: 0;
	visibility: hidden;
}

.registration-title[data-reg-status="inprogress"] .registration-inprogress {
	transform: translateX(0);
	opacity: 1;
	visibility: visible;
}

.registration-title[data-reg-status="success"] .registration-success {
	transform: translateX(0);
	opacity: 1;
	visibility: visible;
}

.registration-title[data-reg-status="success"] .registration-inprogress {
	transform: translateX(50px);
	opacity: 0;
	visibility: hidden;
}

.auth-container .floaty input,
.reg-box .floaty input {
	width: 100%;
}
.auth-container .floaty-element,
.event-box .floaty-element {
	width: 48.98%; /*240px*/
}
.reg .floaty-element {
	width: 50%;
}
.floaty-element.left,
.floaty-element1.left,
.reg .floaty-element.left {
	float: left;
}
.floaty-element.right,
.floaty-element1.right,
.reg .floaty-element.right {
	float: right;
}
@media (max-width: 766px) {
	.reg .floaty-element.right {
		display: none;
	}
	.reg .floaty-element.left {
		width: 100%;
	}
}

.auth-container .floaty-element.city {
	width: 73.47%; /*360px*/
}
.auth-container .floaty-element.postal {
	width: 24.49%; /*120px*/
}
.event-box .flexy-element {
	width: 48%;
}

.flexy {
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	flex-flow: row nowrap;
	-webkit-box-pack:justify;
	-webkit-justify-content:justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.flexy .flexy-element.details {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.flexy .title {
	display: inline-block;
}

.boldy {
	font-weight: 700;
	font-size: 20px;
}
/* flexy K.O. below 768px */
@media(max-width:768px) {
	.flexy.flexy-md {
		display:block;
	}

	.flexy.flexy-md .presenter {
		padding:8px 16px 16px;
	}

}

/* Modified accordion-panel */

.auth-container .accordion-panel {
	margin-bottom: 30px;
}

.auth-container .accordion-panel > .accordion-group:last-child,
.auth-container .accordion-panel > .non-accordion-group:last-child {
	border-bottom: 1px solid #e2e5e8;
}
.auth-container .accordion-panel > .accordion-group > .accordion-header,
.auth-container .accordion-panel > .non-accordion-group > .accordion-header {
	overflow: hidden;
	/* height: 51px; */
	font-size: 24px;
	color: #232157;
	position: relative;
	cursor: pointer;
	line-height: 51px;
	border-top: 1px solid #e2e5e8;
}

.auth-container .accordion-panel > .accordion-group > .accordion-header > *,
.auth-container .accordion-panel > .non-accordion-group > .accordion-header > * {
	display: block;
	padding: 0 0 0 50px;
}

.auth-container .accordion-panel > .accordion-group > .accordion-header > *:hover,
.auth-container .accordion-panel > .non-accordion-group > .accordion-header > *:hover {
    text-decoration: none;
}

@media (max-width:766px) {
	.auth-container .accordion-panel > .accordion-group > .accordion-header,
	.auth-container .accordion-panel > .non-accordion-group > .accordion-header {
		height:auto;
		font-size: 20px;
		padding: 6px 0 6px 40px;
		line-height:normal;
	}

}

.auth-container .accordion-panel > .accordion-group > .accordion-header > .accordionArrowParent,
.auth-container .accordion-panel > .non-accordion-group > .accordion-header > .accordionArrowParent {
	margin-top: 7px !important;
}
.auth-container .accordion-panel > .accordion-group > .accordion-header > .accordionArrow,
.auth-container .accordion-panel > .non-accordion-group > .accordion-header > .accordionArrow {
	padding-top: 10px !important;
}
.auth-container .accordion-panel > .accordion-group.open > .accordion-header > .accordionArrowParent > .accordionArrow,
.auth-container .accordion-panel > .non-accordion-group.open > .accordion-header > .accordionArrowParent > .accordionArrow{
	transform: none;
}
.auth-container .accordion-panel > .accordion-group.close > .accordion-header > .accordionArrowParent > .accordionArrow,
.auth-container .accordion-panel > .non-accordion-group.close > .accordion-header > .accordionArrowParent > .accordionArrow {
	transform: rotate(180deg);
}
.auth-container .accordion-panel > .accordion-group > .accordion-header > .accordion-header-text,
.auth-container .accordion-panel > .non-accordion-group > .accordion-header > .accordion-header-text{
	padding-left: 25px;
}
.auth-container .accordion-panel > .accordion-group.close > .accordion-header:hover,
.auth-container .accordion-panel > .non-accordion-group.close > .accordion-header:hover{
	background: rgba(123,122,154,0.1);
}
.auth-container .accordion-panel > .accordion-group > .accordion-body,
.auth-container .accordion-panel > .non-accordion-group > .accordion-body{
	height: 0;
	transition: 0.3s cubic-bezier(0.3, 0, 0.3, 1) height;
	padding-left: 50px;
	/*margin-left: 35px;*/
}

@media (max-width: 560px) {
	.auth-container .accordion-panel > .accordion-group > .accordion-body,
	.auth-container .accordion-panel > .non-accordion-group > .accordion-body {
		margin-left: 0;
	}
}

.auth-container .accordion-panel > .accordion-group.open > .accordion-body,
.auth-container .accordion-panel > .non-accordion-group.open > .accordion-body{
	overflow: visible;
	height: auto;
}

@media (max-width: 520px) {
	.auth-container .accordion-panel > .accordion-group.open > .accordion-body,
	.auth-container .accordion-panel > .non-accordion-group.open > .accordion-body {
		padding-left: 0;
	}
}

.auth-container .accordion-panel > .accordion-group.noAnim > .accordion-body,
.auth-container .accordion-panel > .non-accordion-group.noAnim > .accordion-body {
	-webkit-transition:none;
	transition: none;
}
.auth-container .accordion-panel > .accordion-group > .accordion-body .description,
.auth-container .accordion-panel > .non-accordion-group > .accordion-body .description {
	margin-bottom: 25px;
	color: #8A8A8A;
}
@media (max-width: 560px) {
	.auth-container .accordion-panel > .accordion-group > .accordion-body .description,
	.auth-container .accordion-panel > .non-accordion-group > .accordion-body .description {
		/* margin-left: 35px; */
	}
}
.auth-container .accordion-panel .accordion-group.open > .accordion-header:before,
.auth-container .accordion-panel .non-accordion-group.open > .accordion-header:before {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}
.auth-container .accordion-panel .accordion-header:before,
.auth-container .non-accordion-group .accordion-header:before {
	width: 15px;
	height: 6px;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-transition: 0.3s ease-in-out transform;
	transition: 0.3s ease-in-out transform;
	content: "";
	display: inline-block;
	vertical-align: middle;
	background: url(../images/arrow-icon.svg) no-repeat left top;
	position: absolute;
	top: 27px;
	left: 8px;
	z-index: -1;
}
.auth-container .non-accordion-group .accordion-header:before {
	background: url(../images/arrow-icon-gray.svg) no-repeat left top;
}

@media (max-width: 766px) {
	.auth-container .accordion-panel .accordion-header:before,
	.auth-container .non-accordion-panel .accordion-header:before {
		/* margin-left:-30px; */
		top: 23px;
		left: 0;
	}
}

/* Authnav O--O--O--O--O */

.authnav {
	position: relative;
	width: 517px;
	height: 50px;
	border-bottom: 1px solid #232157;
	border-right: 75px #fff;
	border-left: 75px #fff;
	box-sizing: content-box;
	margin: 0 auto;
}
.authnav-steps {
	display: block;
	position: absolute;
	top: 40px;
	left: -70px;
	width: 650px;
}
.authnav-step {
	display: inline-block;
	width: 125px;
	vertical-align: top;
}
.authnav-step .circle {
	width: 25px;
	height: 25px;
	background-color: #fff;
	border-radius: 15px;
	border: 1px solid #232157;
	text-align: center;
	margin: 0 auto;
	padding: 2px 0 0;
	box-sizing: border-box;
}

.authnav-step.active .circle {
	background-color: #232157;
	border-color: #232157;
	color: #fff;
	cursor: pointer;
}

.authnav .title {
	text-align: center;
	font-size: 16px;
	color: #8a8a8a;
	padding: 8px 0 0;
}

.authnav-step.active .title {
	color: #232157;
	cursor: pointer;
}

/* Event banner */

.event-box .banner {
	display: block;
	position: relative;
	z-index: 0;
	color: #fff;
	max-width: 285px;
	max-height: 290px;
	margin-bottom: 0;
	text-transform: uppercase;
	font-size: 0;
	width: 47%; /* mod */
	overflow: hidden;
}

.event-box .banner .img-thumbnail {
	height: 300px;
	width: 285px;
	max-width: 100%; /* mod */
	background-size: cover;
	background-position: center center;
	cursor: default;
}

@media (max-width:640px) {
	.event-box .banner {
		position: static;
		padding: 0;
		max-width: none;
		width:100%;
	}

	.event-box .banner .img-thumbnail {
		display:none;
	}

}

.event-box .banner .event-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	font-size: 20px;
	z-index: 1;
	padding: 8px;
	text-align: right;
	background: rgba(35, 33, 87, 0.7);
	overflow: hidden;
	text-overflow: ellipsis;
	text-decoration: none;
	cursor: default;
}

@media (max-width: 640px) {
	.event-box .banner .event-label {
		position:static;
		text-align: left;
		background: transparent;
		color: rgb(35,33,87);
		padding:0;
	}
	.fancybox-close {
		right: 6px;
	}
}

@media (max-width: 560px) {
	.event-box .banner .event-label {
		/*font-size: 18px;*/
	}
}

@media (max-width: 480px) {
	.event-box .banner .event-label {
		/*font-size: 14px;*/
	}
}

/* Event article */

.bet-articles .registrationTime {
	color: #7b7a9a;;
	padding: 8px 16px;
}
@media (max-width: 766px) {
	.bet-articles .registrationTime {
		padding-left: 8px;
	}
}
@media (max-width: 480px) {
	.bet-articles .registrationTime {
		padding-left: 16px
	}
}

.presenter-container {
	text-align: center;
	padding-top: 10px;
}
.bet-articles .presenter-name + .presenter-name:before {
	content: ",";
	display: inline-block;
	width: 3px;
	height: 15px;
}
.bet-articles .presenterImg {
	display: inline-block;
	position: relative;
	text-align: center;
	margin: 8px 0 16px;
}
.bet-articles .presenterImg img{
	max-width: 100%;
	border: 0;
	max-width: 250px;
	max-height: 250px;
}
.presenter-container span.presenter-name {
	display: block;
	font-size: 16px;
}
.bet-articles .img-container-bg-img {
	height: 115px;
}

.bet-articles .event-details-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 0px;
	flex: 1 0 0;
	margin: auto 0;
}

.bet-articles .event-container {
	text-align: center;
	margin: 8px auto 16px;
}
.bet-articles .reg-container {
	font-size: 16px;
	margin: 16px;
}
.bet-articles .reg-status {
	font-size: 16px;
	color: #cc4533;
}
.bet-articles .reg-status .reg-status span {
	color: #cc4533;
}
.bet-articles .right.flexy-element {
	/*    text-align: center;
		flex: 0 0 0;*/
	text-align: right;
	flex:1 1 50%;
}
.bet-articles .right.flexy-element.btn {
	margin-bottom: 8px;
	flex: 0 0 auto;
}
.bet-articles .event-notif {
	color: #cc4533;
	font-size: 16px;
	padding: 10px 0;
}
.bet-articles .event-details-container .map {
	display: inline-block;
	font-size: 0;
	background-image: url(../images/maps-ico.png);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	width: 23px;
	height: 23px;
	line-height: 37px;
	cursor: pointer;
}

/* Messages */
.press-reg::after,
.eventdetails-container::after {
	display: block;
	content: url(../images/bet-logo-simple.svg);
}
.eventdetails-container::after {
	margin-top: 30px;
}
.eventdetails-container div {
	line-height: 37px;
	margin-left: 32px;
}
.eventdetails-container .label {
	width: 160px;
	display: inline-block;
}


/* Legördülő */

.auth-container .selectionDisplay {
	width: 490px;
	line-height: 28px;
	box-sizing: border-box;
	padding: 4px;
	background-color: #f3f3f6;
	overflow-y: auto;
	border: 1px solid #e2e5e8;
	margin-bottom: 15px;
	text-transform: uppercase;
	min-height: 100px;
	max-height: 250px;
}

@media (max-width: 560px) {
	.auth-container .selectionDisplay {
		width: 100%;
	}
}

.auth-container .margin-top {
	margin-top: 10px;
}

.auth-container .selectionChoice {
	background-color: #7b7a9a;
	color: #f3f3f6;
	border: 1px solid #aaa;
	cursor: default;
	margin-right: 5px;
	margin-bottom: 10px;
	padding: 0 5px;
	display: inline-block;
	font-size: 14px;
	line-height: 20px;
}

.auth-container .selectContainer .select2-container--default.select2-container--disabled .select2-selection--multiple {
	background-color: #f3f3f6;
	border: 1px solid #e2e5e8;
}
.auth-container .selectionDisplay .selectionDisplayRow {
	display: inline-block;
}

.auth-container .selectionDisplay .selectionDisplayRow .selectionChoice .selectionChoiceRemove {
	color: #f3f3f6;
	cursor: pointer;
	display: inline-block !important;
	background: url('../../../../default/images/datadwl-delete.svg') no-repeat center !important;
	width: 10px !important;
	height: 10px !important;
	margin-top: 8px;
	margin-right: 5px;
}

.auth-container .selectionDisplay .selectionDisplayRow .selectionChoice .selectionChoiceRemove:hover {
	color: #333;
	background: url('../../../../default/images/datadwl-delete-hover.svg') no-repeat center !important;
}

/* Vlsz. kell */

.auth-container .select2-selection__rendered,
.auth-container .select2-results__option {
	border-radius: 0;
	padding-left: 15px !important;
}
@media (max-width: 766px) {
	.auth-container .select2-container__rendered {
		width: 100% !important;
	}
}
.auth-container .select2-selection__arrow {
	background: url('../images/arrow-icon.svg') no-repeat center;
	background-size: 50%;
	transform: rotate(180deg);
}

.auth-container .select2-selection__arrow b {
	display: none;
}

.auth-container .select2-container .select2-selection--multiple {
	min-height: inherit;
}

.auth-container .select2-container--disabled {
	width: 100% !important;
}
.auth-container .select2-search__field {
	padding: 0;
	margin: 0;
}
.auth-container .select2-results__option--highlighted {
	background-color: #7b7a9a !important;
}
.auth-container .ui-dialog .ui-dialog-titlebar {
	background-color: #7b7a9a;
	color: white;
	text-align: center;
	text-transform: uppercase;
	min-height: 28px;
}

.auth-container .ui-dialog.ui-corner-all {
	border-radius: 0;
	border: 0;
	padding: 0;
}

.auth-container .ui-corner-all {
	border-radius: 0 !important;
}


.auth-registration-succes {
	margin-bottom: 10px;
	color: green;
	font-size: 1.3em;
}
